<template>
    <div>
        <EmployeeManagement_Component></EmployeeManagement_Component>
    </div>
</template>

<script>
import EmployeeManagement_Component from '../../components/PersonalCenter/EmployeeManagement_Component.vue'
export default {
    name: "EmployeeManagement",
    components: { EmployeeManagement_Component }
}
</script>

<style>

</style>