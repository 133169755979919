<template>
    <!-- 员工管理 -->
    <div class="employeeManagement">
        <div class="EnquiryServer">
            <!-- 选择角色 selectRole -->
            <div class="orderNumber">
                <el-select v-model="selectRole" placeholder="选择角色" @change="handleOrderStatus(selectRole)">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label">
                    </el-option>
                </el-select>
            </div>
            <!-- 用户名称或手机 -->
            <div class="orderStatus">
                <!-- <el-select v-model="value" placeholder="用户名称或手机" @change="handleOrderStatus(value)">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label">
                    </el-option>
                </el-select> -->
                <input type="text" placeholder="用户名称或手机">
            </div>
            <!-- 下单范围日期 -->
            <div class="orderRangeDate">
                <el-date-picker prefix-icon="" v-model="value1" type="date" placeholder="注册时间" value-format="yyyy-MM-dd"
                    @change="handleOrderRangeDate(value1)">
                </el-date-picker>
            </div>
            <!-- 搜索按钮 -->
            <div class="server_Btn" @click=handleServer><i class="el-icon-search"></i><span>搜索</span></div>
            <div class="server_Btn" @click=newlyAdded><i class="el-icon-s-custom"></i><span>新增</span></div>
        </div>

        <div class="tabs">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="roleS" label="角色" width="170">
                </el-table-column>
                <el-table-column prop="tellphoneNumber" label="手机号" width="170">
                </el-table-column>
                <el-table-column prop="userName" label="用户名称" width="170">
                </el-table-column>
                <el-table-column prop="registrationTime" label="注册时间" width="450">
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="260" class-name="nana">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">修改</el-button>
                        <el-button type="text" size="small">删除</el-button>
                        <el-button type="text" size="small">重置密码</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="mask" v-show="maskShow">
            <div class="maskBox">
                <div style="position: relative;" class="maskbefore">
                    <div class="mailbox">
                        <input type="text" placeholder="邮箱" v-model="mailbox">
                    </div>
                    <div class="phoneNumber">
                        <input type="text" placeholder="手机号" v-model="phoneNumberS">
                    </div>
                    <div class="userName">
                        <input type="text" placeholder="用户名称" v-model="userNameS">
                    </div>
                    <div class="userPassword">
                        <input type="text" placeholder="用户密码" v-model="userPasswordS">
                    </div>
                    <div class="selectRole">
                        <el-select v-model="selectRoleS" placeholder="选择角色" @change="handleOrderStatus(selectRoleS)">
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                :value="item.label">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="maskBtn">
                        <button @click="cancel">取消</button>
                        <button @click="determine">确认</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EmployeeManagement_Component",
    data() {
        return {
            selectRole: "", //选择角色
            maskShow: false,
            orderNumber: "", //订单号
            orderType: "",
            otherExpenses: "",//下单时间
            mailbox: "", //邮箱  2859403359@qq.com
            phoneNumberS: "", //手机号  15736953769
            userNameS: "",//用户名称    lanan
            userPasswordS: "", //用户密码   123456
            roleS: "", //角色
            selectRoleS: "",
            options: [{
                value: '订舱管理',
                label: '订舱管理'
            }, {
                value: '租箱管理',
                label: '租箱管理'
            }, {
                value: '回箱管理',
                label: '回箱管理'
            }, {
                value: '贷款管理',
                label: '贷款管理'
            },
            ],
            value1: "",
            tableData: [{
                roleS: "11",
                tellphoneNumber: "fsafas",
                userName: "",
                registrationTime: ""
            }
            ]
        }
    },
    methods: {
        handleServer() {
            console.log("*");
        },
        newlyAdded() {
            this.maskShow = true
        },
        handleOrderStatus() {
            console.log("$", this.selectRole);
            console.log("$2", this.selectRoleS);
        },
        cancel() { //取消
            this.maskShow = false
        },
        determine() { //确认
            this.tableData.push({
                tellphoneNumber: this.phoneNumberS,
                userName: this.userNameS,
                roleS: this.selectRoleS
            })
            this.maskShow = false

            console.log(this.tableData);
        }
    }
}
</script>
<!-- RoleManagement -->
<style lang="scss" scoped>
.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: 0px 3px 6px 1px rgba(51, 51, 51, 0.1);

    .maskBox {
        position: absolute;
        left: 50%;
        top: 233px;
        transform: translate(-50%);
        width: 430px;
        height: 416px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
        border-radius: 8px 8px 8px 8px;

        .mailbox {
            width: 370px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            margin: 40px auto 0;
            border: 1px solid #333333;
            overflow: hidden;
            font-size: 14px;

            input {
                width: 100%;
                height: 100%;
                outline: none;
                border: 0;
                padding-left: 16px;
            }
        }

        .phoneNumber {
            width: 370px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #333333;
            margin: 16px auto 0;
            overflow: hidden;

            input {
                width: 100%;
                height: 100%;
                outline: none;
                border: 0;
                padding-left: 16px;
            }
        }

        .userName {
            width: 370px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #333333;
            margin: 16px auto 0;
            overflow: hidden;

            input {
                width: 100%;
                height: 100%;
                outline: none;
                border: 0;
                padding-left: 16px;
            }
        }

        .userPassword {
            width: 370px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #333333;
            margin: 16px auto 0;
            overflow: hidden;

            input {
                width: 100%;
                height: 100%;
                outline: none;
                border: 0;
                padding-left: 16px;
            }
        }

        .selectRole {
            width: 370px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #333333;
            margin: 16px auto 0;
            overflow: hidden;

            .el-select {
                width: 100%;
                height: 100%;

                // ::v-deep .el-input__inner {
                //     border-color: transparent;
                // }
            }
        }

        .maskBtn {
            position: absolute;
            right: 0;
            bottom: -95px;
            padding-right: 30px;
            // padding-top: 16px;
            // margin-top: 56px;

            button {
                width: 80px;
                height: 40px;
                background: #1890FF;
                box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
                border-radius: 4px 4px 4px 4px;
                border: none;
            }

            button:nth-child(1) {
                margin-right: 16px;
                font-size: 16px;
                font-weight: 500;
                color: #333333;
            }
        }

        .maskbefore::before {
            position: absolute;
            bottom: -40px;
            content: '';
            width: 100%;
            height: 1px;
            background-color: #999999;
        }
    }
}

::v-deep .el-table tr:nth-child(2n-1) {
    background: #F4F4F4;
}

::v-deep .el-table tr {
    height: 84px;
}

::v-deep .el-table__cell .cell {
    // height: 24px;
    line-height: 24px;
    font-size: 14px;
}

::v-deep .el-table_1_column_11 {
    text-align: center;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
    width: 28px;
    height: 28px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    color: #666666;
    border: 1px solid #999999;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px; //修改选中后的样式
    color: #1890FF;
    border: 1px solid #1890FF;
}

::v-deep .el-pager .active {
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    color: #1890FF;
}

::v-deep .is-leaf {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
}

::v-deep .el-table_1_column_5 {
    text-align: center;
}

// ::v-deep .el-select .el-input__inner:focus {
//     border-color: transparent;
// }

// ::v-deep .el-select .el-input.is-focus .el-input__inner {
//     border-color: transparent;
// }

.employeeManagement {
    margin: 0 auto;
    max-width: 1526px;
    height: 520px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    padding-top: 24px;
    padding-left: 32px;

    .EnquiryServer {
        display: flex;
        align-items: center;
        // padding-left: 32px;
        height: 40px;

        .orderNumber {
            width: 180px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;

            // input {
            //     width: 100%;
            //     height: 100%;
            //     padding-left: 16px;
            //     font-size: 14px;
            //     font-weight: 400;
            //     color: #333333;
            //     border-radius: 4px 4px 4px 4px;
            //     border: 1px solid #C0C4CC;
            //     cursor: pointer;
            // }

            // input:focus {
            //     outline: 1px solid #409EFF;
            // }
        }

        .orderStatus {
            width: 260px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            margin-left: 24px;

            input {
                width: 100%;
                height: 100%;
                padding-left: 16px;
                border-radius: 4px 4px 4px 4px;
                color: #333333;
                border: 1px solid #C0C4CC;
                cursor: pointer;
            }
            
            input::-webkit-input-placeholder {
                /* WebKit browsers */
                color: #999999;
            }

            input:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                color: #999999;
            }

            input::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: #999999;
            }

            input:-ms-input-placeholder {
                /* Internet Explorer 10+ */
                color: #999999;
            }

            input:focus {
                outline: 1px solid #409EFF;
            }
        }

        .orderRangeDate {
            width: 260px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            // border: 1px solid #999999;
            margin-left: 24px;

            .el-date-editor {
                width: 100%;
                height: 100%;

                // ::v-deep .el-input__inner {
                //     border-color: #999999;
                // }
            }

            ::v-deep .el-input__prefix {
                display: none;
            }
        }

        .server_Btn {
            width: 120px;
            height: 40px;
            background: #1890FF;
            box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            line-height: 40px;
            margin-left: 24px;
            color: #FFFFFF;
        }
    }

    .tabs {
        margin-top: 59px;

        ::v-deep .nana {
            text-align: center;
        }

        .el-button {
            padding: 6px 12px;
            color: #FFFFFF;
        }

        .el-button:nth-child(1) {
            background: #1890FF;
            box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
            border-radius: 4px 4px 4px 4px;
        }

        .el-button:nth-child(2) {
            background: #D4252C;
            box-shadow: 0px 3px 6px 1px rgba(212, 37, 44, 0.3);
            border-radius: 4px 4px 4px 4px;
        }

        .el-button:nth-child(3) {
            background: #4CAF50;
            box-shadow: 0px 3px 6px 1px rgba(76, 175, 80, 0.3);
            border-radius: 4px 4px 4px 4px;
        }
    }

}
</style>